import type {
  GetBorrowerCreditFacilityResponse,
  GetCreditFacilityFundRequestResponse,
} from '~/types/apiResponse/creditFacility.response'

export const useCreditFacilityStore = defineStore('creditFacility', () => {
  const creditFacilityDetails = ref<GetBorrowerCreditFacilityResponse>()
  const creditFacilityRequestFundingDetails =
    ref<GetCreditFacilityFundRequestResponse>()

  const facilityAmount = computed(
    () => creditFacilityDetails.value?.facility_amount,
  )

  const status = computed(() => creditFacilityDetails.value?.status || null)

  const validity = computed(() => {
    if (!creditFacilityDetails.value) return null
    const { valid_from, valid_to } = creditFacilityDetails.value
    return {
      from: valid_from,
      to: valid_to,
    }
  })

  const deactivationReasons = computed(() => {
    if (!creditFacilityDetails.value) return []

    return creditFacilityDetails.value.deactivation_reasons
  })

  const creditFacilityUpdateRequest = computed(() => {
    if (!creditFacilityDetails.value) return null
    return creditFacilityDetails.value.credit_facility_update_request || null
  })

  const creditFacilityIsActive = computed(() => {
    return status.value?.status === 'Active'
  })

  const creditFacilityIsDisabled = computed(
    () => status.value?.status === 'Inactive',
  )

  const deactivationReasonIds = computed(() => {
    return deactivationReasons.value.map((reason) => reason.id)
  })

  return {
    creditFacilityDetails,
    creditFacilityRequestFundingDetails,
    facilityAmount,
    status,
    validity,
    deactivationReason: deactivationReasons,
    creditFacilityIsActive,
    creditFacilityIsDisabled,
    deactivationReasonIds,
    creditFacilityUpdateRequest,
  }
})
